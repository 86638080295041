module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"A Website Company","short_name":"Webnco","description":"The application does cool things and makes your life better.","lang":"en","display":"standalone","icon":"src/apple-touch-icon.png","start_url":"/","background_color":"#663399","theme_color":"#fff","localize":[{"start_url":"/de/","lang":"de","name":"A Website Company","short_name":"Webnco","description":"Die Anwendung macht coole Dinge und macht Ihr Leben besser."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9cb90a6003754e409774c8a04d2db4b5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","de"],"defaultLanguage":"en","siteUrl":"https://webnco.xyz","trailingSlash":"always","i18nextOptions":{"defaultNS":"common","debug":true,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/web-calculator","getLanguageFromPath":true,"excludeLanguages":["es"]},{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
