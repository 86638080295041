import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp, getApps, getApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const isProd = process.env.NODE_ENV === 'production';

// Replace these with your own Firebase config values
const firebaseConfig = isProd ? {
    apiKey: "AIzaSyCQ7oClxSzQFB_XALhDM5UHyuZotm_RUBQ",
    authDomain: "webco-6213d.firebaseapp.com",
    databaseURL: "https://webco-6213d.firebaseio.com",
    projectId: "webco-6213d",
    storageBucket: "webco-6213d.appspot.com",
    messagingSenderId: "804952712830",
    appId: "1:804952712830:web:1d4d886e98c8a8bde21a2f",
    measurementId: "G-DD28WHR74K",
    automaticDataCollectionEnabled: true
} : {};

const FirebaseContext = createContext<FirebaseApp | null>(null);

export const FirebaseProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);

    useEffect(() => {
        if (!isProd) {
            console.log('Firebase is not initialized in non-prod mode');
            return;
        }
        if (!getApps().length) {
            setFirebaseApp(initializeApp(firebaseConfig));
        } else {
            setFirebaseApp(getApp());
        }
    }, []);

    return (
        <FirebaseContext.Provider value={firebaseApp}>
            {children}
        </FirebaseContext.Provider>
    );
}

export const useFirebase = () => {
    let firebase = useContext(FirebaseContext);
    if (!firebase && isProd) {
        firebase = getApps().length ? getApp() : initializeApp(firebaseConfig);
    }
    return firebase;
};

export const useFirebaseAnalytics = () => {
    const firebaseApp = useFirebase();
    return () => {
        if (!firebaseApp) {
            console.log('Firebase analytics is not available in non-prod mode');
            return;
        }
        return getAnalytics(firebaseApp);
    };
};

export const useLogEvent = () => {
    const getAnalytics = useFirebaseAnalytics();

    const logEventWrapper = (eventName: string, eventParams?: { [key: string]: any }) => {
        const analytics = getAnalytics();
        if (!analytics) {
            console.log(`Event "${eventName}" would be logged here`, eventParams);
            return;
        }
        logEvent(analytics, eventName, eventParams);
    };

    return logEventWrapper;
};